import { onAuthStateChanged } from '@firebase/auth'
import { doc, onSnapshot, Unsubscribe } from 'firebase/firestore'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { auth, firestore } from '@/services/firebase.service'
import { SET_USER_STATE } from '@/store/user/actions/types'
import { sha1Encode } from '@/utils/helpers'
import usePatreonTier from '@/utils/usePatreonTier'

let unsub: Unsubscribe | undefined

const FirebaseAuth = () => {
  const dispatch = useDispatch()
  const patreonTier = usePatreonTier()

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Mediavine
        const emailHash = await sha1Encode(user.email as string)
        window.addEventListener('mediavineIdentityReady', () => {
          if (!patreonTier) {
            // @ts-ignore
            window.$adManagementConfig.web.identityOptIn({ email: emailHash })
          }
        })

        // ATS
        if (!patreonTier) {
          // @ts-ignore
          window.ats.setAdditionalData({ type: 'email', id: user.email })
        }

        unsub = onSnapshot(doc(firestore, 'users', user.uid), (doc) => {
          const data = doc.data()

          // Use subobject reference to avoid stale callback
          dispatch({
            type: SET_USER_STATE,
            payload: {
              uid: user.uid,
              ...data,
            },
          })
        })
      } else if (unsub) {
        unsub()
      }
    })
  }, [])

  return null
}

export default FirebaseAuth
